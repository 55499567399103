import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { DailyQuizimg } from "assets/images";
import "./Home.scss";
import DevelopmentVideoSlider from "components/development-video-slider/DevelopmentVideoSlider";
import Testimonials from "components/testimonial/Testimonial";
import FacultiesList from "components/faculties-list/FacultiesList";
import { homeData } from "services/home";
import UpcomingCourses from "./Section/UpcomingCourses";
import OurPrograms from "components/our-programs/OurPrograms";
import OurLocation from "components/our-location/OurLocation";
import HomeSlider from "./Section/HomeSlider";
import InstituteToppers from "components/institute-toppers/InstituteToppers";
import { errorResponse, failResponse } from "config/config";
import OurCourses from "./Section/OurCourses";
import HumanityCourses from "./Section/HumanityCourses";

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [toppersArray, setToppersArray] = useState([]);
  const [whyJoinUs, setWhyJoinUs] = useState([]);
  const [tabletProgram, setTabletProgram] = useState({});
  const [mobileProgram, setMobileProgram] = useState({});
  const [classroomProgram, setClassroomProgram] = useState({});
  const [regionalProgram, setRegionalProgram] = useState([]);

  const [loading, setLoading] = useState(true);

  // --------------- getting all home page data ----------------//
  React.useLayoutEffect(() => {
    const getHomeData = async () => {
      try {
        setLoading(true);
        const res = await homeData({ type: "home" });

        if (res.code === 200) {
          setBanners(res.data.banners.rows || []);
          setFaculties(res.data.faculties.rows || []);
          setTestimonials(res.data.testimonial.rows || []);
          setSubjects(res.data.subjects.rows || []);
          setToppersArray(res.data.ourToppers || []);
          setWhyJoinUs(res.data.whyJoinUs || []);
          setTabletProgram(res.data.ourPrograms.online || {});
          setMobileProgram(res.data.ourPrograms.mobile || {});
          setClassroomProgram(res.data.ourPrograms.classroom || {});
          setRegionalProgram(res.data.ourPrograms.regional || []);
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    // calls all the master functions
    getHomeData();

    // eslint-disable-next-line
  }, []);

  return (
    <section className="Home">
      {/* ///////////////////// banner Slider ///////////////////////*/}
      <HomeSlider banners={banners} />

      {/* /////////////////////institute toppers //////////////////// */}
      <InstituteToppers
        title={"Our Toppers"}
        subTitle={
          "If you want to be an IAS, IFS, IPS, IRS officer like them then join us now"
        }
        toppersArray={toppersArray}
        loading={loading}
      />

      {/* ////////////////////// Our Courses /////////////////// */}

      <OurCourses />

      {/* ////////////////////// Skilled Faculties /////////////////// */}
      <div className="daily_quiz">
        <div className="container">
          <div className="daily_quiz_content">
            <div className="image_wrap">
              <img src={DailyQuizimg} alt="Shubhra Ranjan" loading="lazy" />
            </div>

            <div className="content">
              <h4>Take our Daily Quiz</h4>
              <p>Help sharpen your skills for upcoming exams</p>
            </div>
            <div className="button_wrap fill">
              <Link to="/daily-quiz" className="button">
                Take quiz
                <span className="icon">
                  <BsArrowRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FacultiesList faculties={faculties} loading={loading} />


      {/* ////////////////////// Upcoming Exam and Course /////////////////// */}

      <UpcomingCourses subjects={subjects} />

      

      

      {/* ////////////////////// Our Programmes /////////////////// */}

      <OurPrograms
        tablet={tabletProgram}
        mobile={mobileProgram}
        classroom={classroomProgram}
        regional={regionalProgram}
        loading={loading}
      />

    <HumanityCourses/>
      

      {/* ////////////////////// Daily Quiz /////////////////// */}

   

      <DevelopmentVideoSlider sliderItem={whyJoinUs} />

      {/* ////////////////////// Testimonails Slider /////////////////// */}

      <Testimonials testimonials={testimonials} loading={loading} />

      {/* ////////////////////// Number Data /////////////////// */}
      <div className="number_data spacing">
        <div className="container">
          <div className="number_data_content">
          <ul>
              <li>
                <h3>62,000 + </h3> <span>Students Tutored</span>
              </li>
              <li>
                <h3>50 + </h3> <span>Courses Offered</span>
              </li>
              <li>
                <h3>20 + </h3> <span>Experienced faculties</span>
              </li>
              <li>
                <h3>8 </h3> <span>Regional Centers</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* ////////////////////// Our Location /////////////////// */}
      <OurLocation
        // description={
        //   "Learn and train with top faculty from any one of our 17 locations across the country"
        // }
        description={
          "Learn and train with top faculties from any one of our below regional centres across the country."
        }
      />
      

    </section>
    
  );
};

export default Home;
