import React, { useEffect, useState } from "react";
import "./Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const PSIR = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 500;
            margin-top: 10px;
        }
        body{
        line-height:35px;
        }
        .title{
        color:#00239c;
        }
        .title1{
        color:#D2042D;
        }
        .pagetitle{
        color:#00239c;
        text-align: center;font-size: 26px;
        }
    </style>
</head>
<body>
    <h3 class="pagetitle">PSIR Syllabus</h3>
    <strong class="title1">PAPER-I</strong>
    <div class="sub-section">
      <strong class="title">Political Theory and Indian Politics</strong>
      <ol>
        <li><strong>Political Theory:</strong> meaning and approaches.</li>
        <li><strong>Theories of state:</strong> Liberal, Neo-liberal, Marxist, Pluralist, Post-colonial, and Feminist.</li>
        <li><strong>Justice:</strong> Conceptions of justice with special reference to Rawl’s theory of justice and its communitarian critiques.</li>
        <li><strong>Equality:</strong> Social, political, and economic; relationship between equality and freedom; Affirmative action.</li>
        <li><strong>Rights:</strong> Meaning and theories; different kinds of rights; Concept of Human Rights.</li>
        <li><strong>Democracy:</strong> Classical and contemporary theories; different models of democracy—representative, participatory, and deliberative.</li>
        <li><strong>Concept of power:</strong> hegemony, ideology, and legitimacy.</li>
        <li><strong>Political Ideologies:</strong> Liberalism, Socialism, Marxism, Fascism, Gandhism, and Feminism.</li>
        <li><strong>
          Indian Political Thought:</strong>
          <ul>
            <li>Dharamshastra, Arthashastra, and Buddhist Traditions</li>
            <li>Sir Syed Ahmed Khan, Sri Aurobindo, M. K. Gandhi, B. R. Ambedkar, M. N. Roy</li>
          </ul>
        </li>
        <li><strong>
          Western Political Thought:</strong>
          <ul>
            <li>Plato, Aristotle, Machiavelli, Hobbes, Locke, John S. Mill</li>
            <li>Marx, Gramsci, Hannah Arendt</li>
          </ul>
        </li>
      </ol>
    </div>
    <div class="sub-section">
      <strong class="title">Indian Government and Politics</strong>
      <ol>
        <li><strong> 
          Indian Nationalism:</strong>
          <ul>
            <li>Political Strategies of India’s Freedom Struggle: Constitutionalism to mass Satyagraha, Non-cooperation, Civil Disobedience; Militant and Revolutionary Movements, Peasant and Workers Movements.</li>
            <li>Perspectives on Indian National Movement: Liberal, Socialist, and Marxist; Radical Humanist and Dalit.</li>
          </ul>
        </li>
        <li><strong> Making of the Indian Constitution:</strong> Legacies of the British rule; different social and political perspectives.</li>
        <li><strong> Salient Features of the Indian Constitution:</strong> The Preamble, Fundamental Rights and Duties, Directive Principles; Parliamentary System and Amendment Procedures; Judicial Review and Basic Structure doctrine.</li>
        <li><strong> Principal Organs of the Union Government:</strong> Envisaged role and actual working of the Executive, Legislature, and Supreme Court. Principal Organs of the State Government: Envisaged role and actual working of the Executive, Legislature, and High Courts.</li>
        <li><strong> Grassroots Democracy:</strong> Panchayati Raj and Municipal Government; Significance of 73rd and 74th Amendments; Grassroot movements.</li>
        <li><strong> 
          Statutory Institutions/Commissions:</strong>
          <ul>
            <li> Election Commission, Comptroller and Auditor General</li>
            <li> Finance Commission, Union Public Service Commission</li>
            <li> National Commissions (SCs, STs, Women, Minorities, Backward Classes)</li>
            <li> National Human Rights Commission</li>
          </ul>
        </li>
        <li><strong> Federalism:</strong> Constitutional provisions; changing nature of centre-state relations; integrationist tendencies and regional aspirations; inter-state disputes.</li>
        <li><strong> Planning and Economic Development: </strong>Nehruvian and Gandhian perspectives; Role of planning and public sector; Green Revolution, land reforms and agrarian relations; liberalization and economic reforms.</li>
        <li><strong> Caste, Religion, and Ethnicity in Indian Politics.</strong></li>
        <li><strong> Party System:</strong> National and regional political parties, ideological and social bases of parties; Patterns of coalition politics; Pressure groups, trends in electoral behaviour; changing socio-economic profile of Legislators.</li>
        <li><strong> Social Movement: </strong>Civil liberties and human rights movements; women’s movements; environmentalist movements.</li>
      </ol>
    </div>
    <strong class="title1">PAPER-II</strong>
    <div class="sub-section">
      <strong class="title">Comparative Politics and International Relations</strong>
      <ol>
        <li> <strong> Comparative Politics: </strong> Nature and major approaches; Political economy and political sociology perspectives; Limitations of the comparative method.</li>
        <li> <strong> State in Comparative Perspective: </strong> Characteristics and changing nature of the State in capitalist and socialist economies, and advanced industrial and developing societies.</li>
        <li> <strong> Politics of Representation and Participation: </strong> Political parties, pressure groups, and social movements in advanced industrial and developing societies.</li>
        <li> <strong> Globalisation: </strong> Responses from developed and developing societies.</li>
        <li> <strong> Approaches to the Study of International Relations: </strong> Idealist, Realist, Marxist, Functionalist, and Systems theory.</li>
        <li> <strong> Key Concepts in International Relations: </strong> National interest, security, and power; Balance of power and deterrence; Transnational actors and collective security; World capitalist economy and globalisation.</li>
        <li> <strong> 
          Changing International Political Order:</strong>
          <ul>
            <li>Rise of superpowers; Strategic and ideological Bipolarity, arms race and cold war; Nuclear threat.</li>
            <li>Non-aligned Movement: Aims and achievements.</li>
            <li>Collapse of the Soviet Union; Unipolarity and American hegemony; Relevance of non-alignment in the contemporary world.</li>
          </ul>
        </li>
        <li> <strong> 
          Evolution of the International Economic System: </strong>
          <ul>
            <li>From Brettonwoods to WTO</li>
            <li>Socialist economies and the CMEA</li>
            <li>Third World demand for new international economic order</li>
          </ul>
        </li>
        <li> <strong> United Nations: </strong> Envisaged role and actual record; Specialized UN agencies—aims and functioning; need for UN reforms.</li>
        <li> <strong> Regionalisation of World Politics: </strong> EU, ASEAN, APEC, SAARC, NAFTA.</li>
        <li> <strong> Contemporary Global Concerns: </strong> Democracy, human rights, environment, gender justice, terrorism, nuclear proliferation.</li>
      </ol>
    </div>
    <div class="sub-section">
      <strong class="title">India and the World</strong>
      <ol>
        <li> <strong> Indian Foreign Policy: </strong> Determinants of foreign policy; the institutions of policymaking; Continuity and change.</li>
        <li> <strong> India’s Contribution to the Non-Alignment Movement: </strong> Different phases; Current role.</li>
        <li> <strong> 
          India and South Asia: </strong>
          <ul>
            <li>Regional Co-operation: SAARC—past performance and future prospects.</li>
            <li>South Asia as a Free Trade Area.</li>
            <li>India’s “Look East” policy.</li>
            <li>Impediments to regional co-operation: River water disputes; illegal cross-border migration; Ethnic conflicts and insurgencies; Border disputes.</li>
          </ul>
        </li>
        <li> <strong> India and the Global South: </strong> Relations with Africa and Latin America; Leadership role in the demand for NIEO and WTO negotiations.</li>
        <li> <strong> India and the Global Centres of Power: </strong> USA, EU, Japan, China, and Russia.</li>
        <li> <strong> India and the UN System: </strong> Role in UN Peacekeeping; Demand for Permanent Seat in the Security Council.</li>
        <li> <strong> India and the Nuclear Question:  </strong>Changing perceptions and policy.</li>
        <li> <strong> Recent Developments in Indian Foreign Policy: </strong> India’s position on the recent crises in Afghanistan, Iraq, and West Asia, growing relations with the US and Israel; Vision of a new world order.</li>
      </ol>
    </div>
    </body>
</html>
`);
  });

  useEffect(() => {
    // Function to fetch the content
    const fetchUPSCcontent = async () => {
      try {
        // const res = await GetUPSCcontent();
        // if (res.code === 200) {
        //   const { htmlcontent } = res.data.content[3];
        //   setContent(htmlcontent);
        // } else {
        //   failResponse(res);
        // }
      } catch (err) {
        errorResponse(err);
      }
    };

    // Function to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    fetchUPSCcontent(); // Fetch content on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []); // Empty dependency array ensures this runs only once (on mount)

  // Handle back button functionality
  const handleBack = () => {
    navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
  };

  return (
    <div className="syllabus-container">
      {isMobile && (
        <div className="mobile-back-arrow" onClick={handleBack}>
          <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
          {/* Font Awesome Arrow Icon */}
        </div>
      )}
      <div className="subsection-prelims-syllabus-container">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default PSIR;
