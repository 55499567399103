import React, { useState, useEffect } from "react";
import { Col, Row, Nav } from "react-bootstrap";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import './FreeResources.scss'; // Import your custom CSS file
import { Pageimg } from "assets/icons";
import { FaCaretDown, FaCaretUp, FaAngleRight, FaBook, FaFileAlt, FaLightbulb, FaScroll, FaGlobeAmericas, FaUniversity, FaChartLine, FaBalanceScale } from "react-icons/fa"; // Import arrow icons and logo icons
const FreeResources = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const { from } = location.state || {};

    const [activeKey, setActiveKey] = useState(''); // default section
    const [subNavVisible, setSubNavVisible] = useState(false); // keep subNavVisible true if 'section1' is active
    const [subNavVisible2, setSubNavVisible2] = useState(false); // keep subNavVisible true if 'section1' is active
    const [subNavVisible3, setSubNavVisible3] = useState(false); // keep subNavVisible true if 'section1' is active
    const [subNavVisible4, setSubNavVisible4] = useState(false); // keep subNavVisible true if 'section1' is active
    const [subNavVisible1, setSubNavVisible1] = useState(false); // keep subNavVisible true if 'section1' is active


    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath === "/freeresources") {
            setActiveKey('FRPrelims');
            navigate('/freeresources/FRPrelims'); 
            setSubNavVisible(true);
            setSubNavVisible2(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);
            return;
        }

        //////////////////////////////////// For the first menu(Prelims) /////////////////////////////////////////////
        if (currentPath === "/freeresources/FRAboutUPSC" && from === "FRMenu") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(true);

        }
        if (currentPath === "/freeresources/FRPrelims" && from === "FRMenu") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(true);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);

        }
        if (currentPath === "/freeresources/FRMains" && from === "FRMenu") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible2(true);
            setSubNavVisible4(false);
            setSubNavVisible1(false);

        }
        if (currentPath === "/freeresources/FROptional" && from === "FRMenu") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(false);
            setSubNavVisible3(true);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);

        }
        if (currentPath === "/freeresources/FRInterview" && from === "FRMenu") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(true);
            setSubNavVisible1(false);

        }
        if (currentPath === "/freeresources/FRPrelimsSyllabus") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(true);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);

        }
        if (currentPath === "/freeresources/FRPrelimsPYQPrelims") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(true);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRPrelimsPrelimsStrategy") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(true);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRPrelimsHotTopicsforPrelims") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible(true);
            setSubNavVisible3(false);
            setSubNavVisible2(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }

        //////////////////////////////////// For the first menu(Mains) /////////////////////////////////////////////

        if (currentPath === "/freeresources/FRMainsSyllabus") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsEssay") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsPYQMains") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsHotTopicsforMains") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsGS1") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsGS2") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsGS3") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsGS4") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRMainsPSIR") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible2(true);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }

        //////////////////////////////////// For the first menu(Optional) /////////////////////////////////////////////

        if (currentPath === "/freeresources/FROptionalPSIR") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible3(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);



        }
        if (currentPath === "/freeresources/FROptionalHistory") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible3(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FROptionalSociology") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible3(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FROptionalGeography") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible3(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible4(false);
            setSubNavVisible1(false);


        }
        

        //////////////////////////////////// For the first menu(Interview) /////////////////////////////////////////////

        if (currentPath === "/freeresources/FRInterviewToppersInterview") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible4(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRInterviewTopInterviews") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible4(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRInterviewToppersList") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible4(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRInterviewPanelList") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible4(true);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible1(false);


        }
        if (currentPath === "/freeresources/FRAboutUPSCContent") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible4(false);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible1(true);


        }
        if (currentPath === "/freeresources/FRAboutUPSCEligibility") {
            setActiveKey(currentPath.split('/').pop()); 
            setSubNavVisible4(false);
            setSubNavVisible2(false);
            setSubNavVisible(false);
            setSubNavVisible3(false);
            setSubNavVisible1(true);


        }
        
    }, [location]);

    // Handle navigation to different sections
    const handleSelect = (eventKey) => {

        // For subsections, we want to keep the subNavVisible true and navigate
        if (eventKey.startsWith('subsection')) {
            setActiveKey(eventKey);
            navigate(eventKey); // Navigate to the subsection
            return; // Exit early to prevent further logic
        }

       
        if (eventKey === 'FRPrelims') {
            setSubNavVisible((prev) => !prev); 
        } else {
            setSubNavVisible(false); 
        }

        if (eventKey === 'FRMains') {
            setSubNavVisible2((prev) => !prev); 
        } else {
            setSubNavVisible2(false); 
        }

        if (eventKey === 'FROptional') {
            setSubNavVisible3((prev) => !prev); 
        } else {
            setSubNavVisible3(false); 
        }

        if (eventKey === 'FRInterview') {
            setSubNavVisible4((prev) => !prev); 
        } else {
            setSubNavVisible4(false); 
        }

        if (eventKey === 'FRAboutUPSC') {
            setSubNavVisible1((prev) => !prev); 
        } else {
            setSubNavVisible1(false); 
        }

        setActiveKey(eventKey);
        navigate(eventKey); 
    };



    return (
        <Row className="mt-4">
            
            {/* Left side navbar */}
            <Col md={3} className="bg-light p-3">
                <div className="nav-scroll-container-free-resources">
                    <Nav
                        variant="pills"
                        className="flex-column custom-nav" // Add custom class here
                        activeKey={activeKey}
                        onSelect={handleSelect}
                    >
                        {/* // */}
                        <Nav.Link
                            eventKey="FRAboutUPSC"
                            className={activeKey === "FRAboutUPSC" ? "active-nav" : ""}
                        >
                            <img src={Pageimg} className = "imgFreeResources"  alt="" loading="lazy" style={{ marginRight: '10px' }} />
                            About UPSC
                            {subNavVisible1 ? <FaCaretUp className="arrow-icon" /> : <FaCaretDown className="arrow-icon" />}
                        </Nav.Link>
                        {subNavVisible1 && (
                            <Nav className="flex-column mt-2">
                                <Nav.Link eventKey="FRAboutUPSCContent" className={activeKey === "FRAboutUPSCContent" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px'}} /> {/* Logo icon */}
                                    
                                    Civil Services Exam
                                </Nav.Link>
                                <Nav.Link eventKey="FRAboutUPSCEligibility" className={activeKey === "FRAboutUPSCEligibility" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Eligibility
                                </Nav.Link>
                            </Nav>
                        )}

                        <Nav.Link
                            eventKey="FRPrelims"
                            className={activeKey === "FRPrelims" ? "active-nav" : ""}
                        >
                            <img src={Pageimg} className = "imgFreeResources"  alt="" loading="lazy" style={{ marginRight: '10px' }} />
                            Prelims
                            {subNavVisible ? <FaCaretUp className="arrow-icon" /> : <FaCaretDown className="arrow-icon" />}
                        </Nav.Link>

                        {subNavVisible && (
                            <Nav className="flex-column mt-2">
                                <Nav.Link eventKey="FRPrelimsSyllabus" className={activeKey === "FRPrelimsSyllabus" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px'}} /> {/* Logo icon */}
                                    
                                    Syllabus
                                </Nav.Link>
                                <Nav.Link eventKey="FRPrelimsPYQPrelims" className={activeKey === "FRPrelimsPYQPrelims" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    PYQ Prelims
                                </Nav.Link>
                                <Nav.Link eventKey="FRPrelimsPrelimsStrategy" className={activeKey === "FRPrelimsPrelimsStrategy" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    Prelims Strategy
                                </Nav.Link>
                                {/* <Nav.Link eventKey="FRPrelimsHotTopicsforPrelims" className={activeKey === "FRPrelimsHotTopicsforPrelims" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    Hot Topics for Prelims
                                </Nav.Link> */}
                            </Nav>
                        )}


                        <Nav.Link eventKey="FRMains" className={activeKey === "FRMains" ? "active-nav" : ""}>
                            <img src={Pageimg} className = "imgFreeResources"  alt="" loading="lazy" style={{ marginRight: '10px' }} />
                            Mains
                            {subNavVisible2 ? <FaCaretUp className="arrow-icon" /> : <FaCaretDown className="arrow-icon" />}
                        </Nav.Link>

                        {subNavVisible2 && (
                            <Nav className="flex-column mt-2">
                                <Nav.Link eventKey="FRMainsSyllabus" className={activeKey === "FRMainsSyllabus" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} /> {/* Logo icon */}
                                    
                                    Syllabus
                                </Nav.Link>
                                {/* <Nav.Link eventKey="FRMainsEssay" className={activeKey === "FRMainsEssay" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Essay
                                </Nav.Link>
                                <Nav.Link eventKey="FRMainsPYQMains" className={activeKey === "FRMainsPYQMains" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    PYQ Mains
                                </Nav.Link>
                                <Nav.Link eventKey="FRMainsHotTopicsforMains" className={activeKey === "FRMainsHotTopicsforMains" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Hot Topics for Mains
                                </Nav.Link>*/}
                                <Nav.Link eventKey="FRMainsGS1" className={activeKey === "FRMainsGS1" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    GS I
                                </Nav.Link>
                                <Nav.Link eventKey="FRMainsGS2" className={activeKey === "FRMainsGS2" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    GS II
                                </Nav.Link>
                                <Nav.Link eventKey="FRMainsGS3" className={activeKey === "FRMainsGS3" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    GS III
                                </Nav.Link>
                                <Nav.Link eventKey="FRMainsGS4" className={activeKey === "FRMainsGS4" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    GS IV
                                </Nav.Link> 
                                <Nav.Link eventKey="FRMainsPSIR" className={activeKey === "FRMainsPSIR" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    PSIR
                                </Nav.Link> 

                            </Nav>
                        )}


                        {/* <Nav.Link eventKey="FROptional" className={activeKey === "FROptional" ? "active-nav" : ""}>
                            <img src={Pageimg} className = "imgFreeResources"  alt="" loading="lazy" style={{ marginRight: '10px' }} />
                            Optional
                            {subNavVisible3 ? <FaCaretUp className="arrow-icon" /> : <FaCaretDown className="arrow-icon" />}
                        </Nav.Link> */}

                        {/* {subNavVisible3 && (
                            <Nav className="flex-column mt-2">
                                <Nav.Link eventKey="FROptionalPSIR" className={activeKey === "FROptionalPSIR" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} /> 
                                    
                                    PSIR (Political Science & International Relations)
                                </Nav.Link>
                                <Nav.Link eventKey="FROptionalHistory" className={activeKey === "FROptionalHistory" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    History
                                </Nav.Link>
                                <Nav.Link eventKey="FROptionalSociology" className={activeKey === "FROptionalSociology" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Sociology
                                </Nav.Link>
                                <Nav.Link eventKey="FROptionalGeography" className={activeKey === "FROptionalGeography" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Geography
                                </Nav.Link>
                            </Nav>
                        )} */}


                        {/* <Nav.Link eventKey="FRInterview" className={activeKey === "FRInterview" ? "active-nav" : ""}>
                            <img src={Pageimg} className = "imgFreeResources"  alt="" loading="lazy" style={{ marginRight: '10px' }} />
                            Interview
                            {subNavVisible4 ? <FaCaretUp className="arrow-icon" /> : <FaCaretDown className="arrow-icon" />}
                        </Nav.Link>

                        {subNavVisible4 && (
                            <Nav className="flex-column mt-2">
                                <Nav.Link eventKey="FRInterviewToppersInterview" className={activeKey === "FRInterviewToppersInterview" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} /> 
                                    
                                    Topper's Interview
                                </Nav.Link>
                                <Nav.Link eventKey="FRInterviewTopInterviews" className={activeKey === "FRInterviewTopInterviews" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Top Interviews
                                </Nav.Link>
                                <Nav.Link eventKey="FRInterviewToppersList" className={activeKey === "FRInterviewToppersList" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Toppers List
                                </Nav.Link>
                                <Nav.Link eventKey="FRInterviewPanelList" className={activeKey === "FRInterviewPanelList" ? "active-nav" : ""}>
                                    <FaAngleRight className="icon-logo" style={{ marginRight: '10px' }} />
                                    
                                    Panel List
                                </Nav.Link>
                            </Nav>
                        )} */}

                    </Nav>
                </div>
            </Col>

            {/* Right side content */}
            <Col md={9} className="p-3">
                <Outlet />
            </Col>
        </Row>
    );
};

export default FreeResources;
