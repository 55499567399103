import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import { Calendarimg, Peopleimg } from "assets/icons";
import { BiRupee } from "react-icons/bi";
import { humanityCourses } from "services/home";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { countArray, momentDateFormat, sliderItem } from "config/utils";
import { errorResponse, failResponse } from "config/config";

const HumanityCourses = () => {
    const [loading, setLoading] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState([]);

    const sliderSettings = {
        dots: true,
        arrows: true,
        slidesToShow: sliderItem(selectedCourses, 5),
        slidesToScroll: 1,
        infinite: true,
        speed: 900,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: sliderItem(selectedCourses, 4),
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 801,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };

    const getHumanityCourses = async () => {
        try {
            setLoading(true);
            const res = await humanityCourses();

            if (res.code === 200) {
                setSelectedCourses(res.data.humanitycourses || []);
            } else {
                failResponse(res);
            }
        } catch (err) {
            errorResponse(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getHumanityCourses();
    }, []);

    return (
        <div className="humanity_courses">
            <div className="container">
                <div className="page_title padding">
                    <h3>Institute for Humanities Courses</h3>
                    <p>View our courses for humanity</p>
                </div>

                <div className="navtab_content">
                    {/* Loading state */}
                    {loading && (
                        <div className="d-flex">
                            {countArray(4).map((data) => (
                                <div className="w-25 mx-1" key={data}>
                                    <CourseSkeleton width="100%" height={390} />
                                </div>
                            ))}
                        </div>
                    )}

                    {/* No courses available */}
                    {!loading && selectedCourses.length === 0 && (
                        <div className="coursecard_wrap">
                            <div className="content text-center text-danger py-5">
                                <h6>No Course Available</h6>
                            </div>
                        </div>
                    )}

                    {/* Courses available */}
                    {!loading && selectedCourses.length > 0 && (
                        <div className="coursecard_wrap">
                            <Slider {...sliderSettings}>
                                {selectedCourses.map((course) => (
                                    <div className="course_card" key={course.ID}>
                                        <div className="image_wrap">
                                            <img
                                                src="https://images.theconversation.com/files/45159/original/rptgtpxd-1396254731.jpg?ixlib=rb-4.1.0&q=45&auto=format&w=1356&h=668&fit=crop"
                                                alt={course.CourseTitle}
                                            />
                                        </div>
                                        <div className="content">
                                            <div className="course_detail">
                                                <h5
                                                    style={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,  // Limits text to 2 lines
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        minHeight: '3rem'
                                                    }}
                                                >
                                                    {course.CourseTitle}
                                                </h5>


                                                <ul>
                                                    <li>
                                                        <img src={Calendarimg} alt="" loading="lazy" />
                                                        Starting from{" "}
                                                        <b>{momentDateFormat(course.StartDate)}</b>
                                                    </li>
                                                    <li>
                                                        <img src={Peopleimg} alt="" loading="lazy" />
                                                        {course.CourseType}
                                                    </li>
                                                    <li>
                                                        <h6 className="price">
                                                            <BiRupee />
                                                            {course.Price}/-
                                                        </h6>
                                                        <span>Inclusive of taxes</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="button_wrap">
                                                <Link to={course.Link} className="button" target="_blank">
                                                    Enroll Now
                                                    <span className="icon">
                                                        <BsArrowRight />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HumanityCourses;
