import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const homeData = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/home?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const upcomingCourseData = async (data) => {
  return decryptData(
    await axios.get(
      `/upcoming-courses?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};
export const humanityCourses = async (data) => {
  return decryptData(
    await axios.get(
      `/humanity-courses?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const ourCoursesData = async (data) => {
  return decryptData(
    await axios.get(
      `/our-courses-data?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const teacherDetailById = async (data) => {
  return decryptData(
    await axios.get(
      `/teacher-details?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

// send request to contact
export const contactUs = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/contactus/submit`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: token,
      }),
    })
  );
};

// get billing courses for contact
export const getBillingCoursesForContact = async (data) => {
  return decryptData(
    await axios.get(
      `/contactus/billing/course?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getAdvertisementData = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/advertisement?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};
